<template>
        <v-app>
          <v-skeleton-loader
              v-bind="attrs"
              width="500"
              type="article, actions"
              v-if="isBusy"
          ></v-skeleton-loader>
          <template v-else>
            <v-row v-if="online_counselling && online_counselling_image">
              <v-col class="text-right">For Live Support
                <a target="_blank" :href="online_counselling">
                  <img :src="online_counselling_image" alt="" width="100px">
                </a>
              </v-col>
            </v-row>
            <div v-if="first_step_completed">

                <v-row>
                  <v-col cols="12"  style="margin-top: 150px" class="mx-15">
                    <v-card-title class="d-flex">
                     <span class="font-weight-bold text-danger title">
                       Thank you for submitting the form! We have sent an email to the address you provided.
                       To complete the verification process, please check your inbox and follow the instructions provided in the email.
                         If you don't see the email in your inbox, please check your spam folder. Once you have verified your email address, you'll be able to proceed with the next steps. Thank you!
                     </span>
                    </v-card-title>
                  </v-col>
                </v-row>

            </div>
            <div v-else>
                <v-row v-if="pre_registration_active">
                  <v-col cols="12"  class="">
                    <v-card-title class="d-flex">
                      <h2>Apply online for the academic {{pre_registration_active.academic_year}} </h2>

                    </v-card-title>
                    <v-card-subtitle class="d-flex">
                      <h6 class="mb-5 mt-5">Fill in the info below to continue applying for admission. Please make sure to continue accurate information in order to avoid any delay in the enrolment process.</h6>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-row>
                        <v-col  cols="12" md="6" lg="6" sm="12" >
                          <v-text-field v-model.trim="basicInfo.first_name" :error="$v.basicInfo.first_name.$error"
                                        outlined
                                        hide-details>
                              <template v-slot:label>
                                  First name  <span class="text-danger">*</span>
                              </template>
                          </v-text-field>
                          <span class="text-danger" v-if="$v.basicInfo.first_name.$error">First name is required</span>
                        </v-col>
                        <v-col  cols="12" md="6" lg="6" sm="12" >
                          <v-text-field v-model.trim="basicInfo.last_name" label="Last name" outlined :error="$v.basicInfo.last_name.$error" hide-details>
                              <template v-slot:label>
                                  Last name  <span class="text-danger">*</span>
                              </template>
                          </v-text-field>
                          <span class="text-danger" v-if="$v.basicInfo.last_name.$error">Last name is required</span>
                        </v-col>
                        <v-col  cols="12" md="6" lg="6" sm="12" >
                          <v-text-field @blur="checkDuplicateEmail" v-model="basicInfo.email"  outlined  :error="$v.basicInfo.email.$error || emailValidationFlag"
                                        hide-details>
                              <template v-slot:label>
                                  Email  <span class="text-danger">*</span>
                              </template>
                          </v-text-field>
                          <span class="text-danger"
                                v-if="$v.basicInfo.email.$error">Email is required</span>
                          <p class="text-danger" v-if="emailValidationFlag">Email should be valid</p>

                        </v-col>
                        <v-col  cols="12" md="6" lg="6" sm="12" >
                          <v-text-field v-model="basicInfo.confirm_email"  @paste="preventPaste" outlined  :error="$v.basicInfo.confirm_email.$error"
                                        hide-details>
                              <template v-slot:label>
                                  Confirm email  <span class="text-danger">*</span>
                              </template>
                          </v-text-field>
                          <span class="text-danger" v-if="$v.basicInfo.confirm_email.$error">This confirmation email doesn't match with email.</span>
                        </v-col>
                        <v-col  cols="12" md="12" lg="12" sm="12" >
                          <v-text-field v-model.trim="basicInfo.phone" outlined  :error="$v.basicInfo.phone.$error" hide-details>
                              <template v-slot:label>
                                  Phone no. <span class="text-danger">*</span>
                              </template>
                          </v-text-field>
                          <span class="text-danger"
                                v-if="$v.basicInfo.phone.$error">Phone is required and must be valid</span>
                        </v-col>
                        <vue-recaptcha
                            ref="invisibleRecaptcha"
                            :sitekey="sitekey"
                            @verify="onCaptchaVerified"
                            :loadRecaptchaScript="true"
                            size="invisible"
                        ></vue-recaptcha>
                      <v-col>
                          <div class="custom-alert warning" v-if="duplicateEmailStatus && duplicateEmailStatus =='ERROR'">
                              <p>
                                  This email has already been used for admission application. If you have incomplete applications with this email then please check your email you can continue application from where you left.
                              </p>
                          </div>
                      </v-col>
                        <v-col cols="12" class="text-right">
                            <v-btn @click.prevent="redirectTo" depressed class="btn btn-standard ml-2"
                                   dark>
                                Cancel
                            </v-btn>
                            <v-btn :loading="buttonLoading" @click.prevent="createNewApplication" depressed class="btn btn-primary btn-continue "
                                 dark>
                            Next
                          </v-btn>

                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
            </div>
          </template>
        </v-app>
</template>
<script>
import StepperMixin from "@/mixins/StepperMixin";
import EssentialService from "@/core/services/enrollment/EssentialService";
import {email, required,sameAs} from "vuelidate/lib/validators";

const enrollmentEssentialService = new EssentialService();
const isPhone = (value) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);

import VueRecaptcha from "vue-recaptcha";

export default {
  name: "AdmissionFirstStep",
  components:{VueRecaptcha},
  props: ['level','program','setting'],
  validations() {
    return {
      basicInfo: {
        first_name: {required},
        last_name: {required},
        email: {required},
        confirm_email: {
          required,
          sameAsPassword: sameAs('email'),
        },
        phone: {required, isPhone},
       /* recaptcha: {required},*/
      },

    }

  },
  data(){
    return{
      online_counselling:null,
      online_counselling_image:null,
      isBusy:false,
      attrs: {},
      pre_registration_active:null,
      basicInfo: {
        pre_registration_id: null,
        email: null,
        confirm_email: null,
        first_name: null,
        last_name: null,
        phone: null,
        level: null,
        program: null,
        dob: null,
        dob_bs: null,
        gender: null,
        nationality: null,
        more_details: null,
        recaptcha: null,
        entrance_date_time: null
      },
      first_step_completed:false,
        buttonLoading:false,
        messages:null,
        duplicateEmailStatus:null,
        emailValidationFlag:false,
    }
  },
  methods:{
   checkDuplicateEmail(){
       if(this.basicInfo.email){
           let data = { email: this.basicInfo.email };
           enrollmentEssentialService
               .checkDuplicateEmail(data)
               .then((response) => {
                   this.messages = response.data.message;
                   this.duplicateEmailStatus = response.data.status;

               })
               .catch((error) => {

               });
       }

   },
    getAllPreRegistrationDetail(){
      if(this.level && this.program){
        enrollmentEssentialService
            .getAllPreRegistrationDetail(this.level,this.program)
            .then(response => {
               this.pre_registration_active=response.data;
                this.online_counselling=this.pre_registration_active && this.pre_registration_active.online_counselling ? this.pre_registration_active.online_counselling: null;
                this.online_counselling_image=this.pre_registration_active &&  this.pre_registration_active.online_counselling_logo_path && this.pre_registration_active.online_counselling_logo_path['real']
                    ? this.pre_registration_active.online_counselling_logo_path['real']:null;
            })
            .catch(error => {

            }).finally(() => {

          })

      }
    },
    redirectTo() {
      this.$confirm({
        message: `Do you want to cancel this application?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.$router.push({name: 'index'});
          }
        }
      });
    },
    createNewApplication(){
      this.$refs.invisibleRecaptcha.execute();
    },
    preventPaste(event) {
      event.preventDefault();
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.$v.basicInfo.$touch();
      if (this.$v.basicInfo.$error) {
        setTimeout(() => {
          this.$v.basicInfo.$reset();
        }, 15000);
      } else {
        let data = this.basicInfo;
        if(this.pre_registration_active){
          data.pre_registration_id = this.pre_registration_active.id;
        }

        data.registration_type = 'pre_registration';
        data.email = this.basicInfo.email;
        data.token = Math.random().toString(36).substring(7);
        this.buttonLoading=true;
        enrollmentEssentialService
            .storeEnrollment(data,data.token)
            .then(response => {
              if(response.data.status=='VALIDATION_ERROR'){
                this.emailValidationFlag=true;
                setTimeout(() => {
                  this.emailValidationFlag=false;
                }, 15000);
              }else{
                this.$snotify.success("Enrolment information updated");
                if(response.data.token){
                  let encryptedKey= response.data.token
                  this.$router.push({
                    name: 'pre-registration',
                    query: { application_id:encryptedKey}
                  })
                }
              }


            })
            .catch(error => {
              //console.log(error);
            })
            .finally(() => {
              this.overlay = false;
                this.buttonLoading=false;
            });
      }
    },
  },
  mounted() {
    if(this.program){
      this.basicInfo.program = this.program;
      this.basicInfo.program_id = this.program;
    }
    if(this.level){
      this.basicInfo.level = this.level;
      this.basicInfo.level_id = this.level;
    }
    this.getAllPreRegistrationDetail();
  },
  computed:{
    sitekey :function ()
    {
      return  this.$store.getters.siteKey;
    },
  }

}
</script>
<style scoped>
.custom-alert {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
}

.custom-alert.warning {
    background-color: #fcf8e3;
    color: #fff;

}
</style>