<template>
  <div
      class="flex-row-fluid d-flex flex-column position-relative  overflow-hidden"
  >
    <div class="d-flex flex-column-fluid flex-center mt-lg-10 mt-lg-0">

      <div class="d-flex flex-column  ">
        <v-app>
          <v-skeleton-loader
              v-bind="attrs"
              width="500"
              type="article, actions"
              v-if="isBusy"
          ></v-skeleton-loader>

          <template v-else>
            <v-container v-if="pre_registration_active">
              <v-row>
                <v-col  outlined>
                 <v-row v-if="online_counselling && online_counselling_image">
                        <v-col class="text-right">For Live Support
                            <a target="_blank" :href="online_counselling">
                                <img :src="online_counselling_image" alt="" width="100px">
                            </a>
                        </v-col>
                    </v-row>
                  <v-stepper v-model="step" flat>
                    <v-stepper-header>
                      <v-stepper-step
                          :complete="step > 1"
                          step="1"
                      >
                        General
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step
                          :complete="step > 2"
                          step="2"
                      >
                        Education
                      </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="3">
                        Other
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-row>
                          <v-col cols="12">
                            <h4>Personal information <span class="text-danger">*</span></h4>
                              <hr/>
                          </v-col>
                          <v-col md="4" lg="4" cols="12">
                            <v-text-field v-model="pre_registration.basic_info.full_name"
                                          outlined dense hide-details>
                                <template v-slot:label>
                                    Full name  <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger"
                                  v-if="$v.pre_registration.basic_info.full_name.$error">Full name is required</span>

                          </v-col>
                          <v-col md="4" lg="4" cols="12">
                              <v-select v-model="pre_registration.basic_info.gender"
                                        :items="gender"
                                        item-value="value" item-text="name"
                                        outlined dense
                                        :error="$v.pre_registration.basic_info.gender.$error"
                                        hide-details>
                                  <template v-slot:label>
                                      Gender  <span class="text-danger">*</span>
                                  </template>
                              </v-select>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.gender.$error">Gender is required</span>

                          </v-col>
                          <v-col md="4" lg="4" cols="12">
                              <v-select v-model="pre_registration.basic_info.nationality"
                                        :items="countries"
                                        item-text="name" item-value="value" label="Nationality"
                                        outlined dense hide-details>
                                  <template v-slot:label>
                                      Nationality  <span class="text-danger">*</span>
                                  </template>
                              </v-select>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.nationality.$error">Nationality is required</span>

                          </v-col>
                          <v-col md="6" lg="6" cols="12">
                            <v-text-field v-model="pre_registration.basic_info.email"
                                         outlined
                                          dense hide-details>
                                <template v-slot:label>
                                    Email  <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger"
                                  v-if="$v.pre_registration.basic_info.email.$error">Email is required and must be valid</span>

                          </v-col>
                          <v-col md="6" lg="6" cols="12">
                            <v-text-field v-model="pre_registration.basic_info.phone" outlined dense hide-details>
                                <template v-slot:label>
                                    Phone  <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.pre_registration.basic_info.phone.$error">Phone is required and must be valid</span>
                          </v-col>


                          <v-col cols="12" md="3" lg="3" sm="12" xs="12">
                            <v-switch @change="changeDOBFormat" class="" :label="!dob_in_ad ? 'DOB in BS':'DOB in AD'"
                                      @click="dob_in_ad = !dob_in_ad"></v-switch>
                          </v-col>
                          <v-col cols="12" md="4" lg="4" sm="12" xs="12" v-if="dob_in_ad">
                            <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dob_ad"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    placeholder="YYYY/MM/DD"

                                >
                                    <template v-slot:label>
                                        DOB(AD) YYYY/MM/DD <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="dob_ad"
                                  :max="currentDate"
                                  @input="menu2 = false; onAdChange()"
                              ></v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="dob_in_ad && $v.pre_registration.basic_info.dob.$error">
                              DOB is required
                            </span>

                          </v-col>
                          <v-col cols="12" md="3" lg="3" sm="12" xs="12" v-if="!dob_in_ad">
                              <v-autocomplete
                                  label="Year"
                                  :items="years"
                                  v-model="dob_bs_year"
                                  :error="$v.pre_registration.basic_info.dob_bs.$error || $v.dob_bs_year.$error"
                                  item-text="title"
                                  item-value="value"
                                  :disabled="loading"
                                  outlined dense required
                                  hide-details
                                  @change="buildDateOfBirth"
                              ></v-autocomplete>
                            <p class="text-danger" v-if="$v.dob_bs_year.$error">
                             This information is required
                            </p>
                          </v-col>

                            <v-col cols="12" md="3" lg="3"  sm="12" xs="12" v-if="!dob_in_ad">
                              <v-select
                                  label="Month"
                                  :items="months"
                                  v-model="dob_bs_month"
                                  item-text="title"
                                  item-value="value"
                                  :disabled="loading"
                                  :error="$v.pre_registration.basic_info.dob_bs.$error || $v.dob_bs_month.$error"
                                  @change="buildDateOfBirth"
                                  outlined dense required>
                              </v-select>
                              <p class="text-danger" v-if="$v.dob_bs_month.$error">
                                This information is required
                              </p>
                            </v-col>


                          <v-col cols="12" md="3" lg="3" sm="12" xs="12" v-if="!dob_in_ad">
                              <v-select label="Day" :items="days" v-model="dob_bs_day" item-text="title" item-value="value"
                                  :disabled="loading" :error="$v.pre_registration.basic_info.dob_bs.$error || $v.dob_bs_day.$error" @change="buildDateOfBirth" outlined dense required>
                              </v-select>
                            <p class="text-danger" v-if="$v.dob_bs_day.$error">
                              This information is required.
                            </p>
                          </v-col>
                          <v-col cols="12">
                            <h4>Permanent address <span class="text-danger">*</span></h4>
                              <hr/>
                          </v-col>
                          <v-col md="4" lg="4" cols="12">
                            <v-select v-model="pre_registration.basic_info.country"
                                      :items="countries" :error="$v.pre_registration.basic_info.country.$error"
                                      item-text="name" item-value="value" label="Country"
                                      outlined
                                      dense hide-details>
                                <template v-slot:label>
                                    Country <span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger"
                                  v-if="$v.pre_registration.basic_info.country.$error">This information is required</span>

                          </v-col>
                          <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.country =='nepal'">
                            <v-select v-model="pre_registration.basic_info.province"
                                      :items="provinces" :error="$v.pre_registration.basic_info.province.$error"
                                      item-text="name" item-value="id" label="Province" outlined
                                      dense hide-details
                                      @change="getDistricts('permanent',pre_registration.basic_info.province)">
                                <template v-slot:label>
                                    Province <span class="text-danger">*</span>
                                </template>
                            </v-select>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.province.$error">This information is required</span>
                          </v-col>
                          <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.country =='nepal'">
                            <v-select v-model="pre_registration.basic_info.district"
                                      :items="districts" :loading="districtLoading"  :error="$v.pre_registration.basic_info.district.$error"
                                      item-text="name" item-value="id" label="District" outlined
                                      @change="getMunicipalities('permanent', pre_registration.basic_info.district)"
                                      dense hide-details>
                                <template v-slot:label>
                                    District <span class="text-danger">*</span>
                                </template>
                            </v-select>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.district.$error">This information is required</span>
                          </v-col>
                          <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.country =='nepal'">
                            <v-select
                                v-model="pre_registration.basic_info.city"
                                outlined dense :loading="municipalityLoading"
                                :items="municipalities"
                                item-text="name"
                                item-value="id" :error="$v.pre_registration.basic_info.city.$error"
                                label="Local level">
                                <template v-slot:label>
                                    Local level <span class="text-danger">*</span>
                                </template>
                            </v-select>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.city.$error">This information is required</span>
                          </v-col>
                          <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.country =='nepal'">
                            <v-select :error="$v.pre_registration.basic_info.ward.$error"
                                v-model="pre_registration.basic_info.ward"
                                outlined dense
                                :items="wards"
                                label="Ward">
                                <template v-slot:label>
                                    Ward <span class="text-danger">*</span>
                                </template>
                            </v-select>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.ward.$error">This information is required</span>
                          </v-col>
                          <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.country =='nepal'">
                            <v-text-field :error="$v.pre_registration.basic_info.area.$error"
                                v-model="pre_registration.basic_info.area"
                                outlined dense
                                label="Area/tole"
                            >
                                <template v-slot:label>
                                    Area/tole <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.area.$error">This information is required</span>
                          </v-col>
                          <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.country =='other'">
                            <v-text-field v-model="pre_registration.basic_info.other_address"
                                          label="address" outlined :error="$v.pre_registration.basic_info.other_address.$error"
                                          dense hide-details>
                                <template v-slot:label>
                                    Address <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                              <span class="text-danger"
                                    v-if="$v.pre_registration.basic_info.other_address.$error">This information is required</span>

                          </v-col>

                            <v-col cols="12">
                                <v-checkbox @change="changeTemporaryAddress" v-model="copy_permanent_address">
                                    <template v-slot:label>
                                        <div> Use permanent address as temporary address</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <h4>Temporary address  <span class="text-danger">*</span></h4>
                                <hr/>
                            </v-col>
                            <v-col md="4" lg="4" cols="12">
                                <v-select v-model="pre_registration.basic_info.temporary_country"
                                          :items="countries" :error="$v.pre_registration.basic_info.temporary_country.$error"
                                          item-text="name" item-value="value" label="Country"
                                          outlined
                                          dense hide-details>
                                    <template v-slot:label>
                                        Country <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span class="text-danger"
                                      v-if="$v.pre_registration.basic_info.temporary_country.$error">This information is required</span>

                            </v-col>
                            <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.temporary_country =='nepal'">
                                <v-select v-model="pre_registration.basic_info.temporary_province"
                                          :items="provinces" :error="$v.pre_registration.basic_info.temporary_province.$error"
                                          item-text="name" item-value="id" label="Province" outlined
                                          dense hide-details
                                          @change="getDistricts('temporary',pre_registration.basic_info.temporary_province)">
                                    <template v-slot:label>
                                        Province <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span class="text-danger"
                                      v-if="$v.pre_registration.basic_info.temporary_province.$error">This information is required</span>
                            </v-col>
                            <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.temporary_country =='nepal'">
                                <v-select v-model="pre_registration.basic_info.temporary_district"
                                          :items="temporary_districts" :loading="temporaryDistrictLoading"  :error="$v.pre_registration.basic_info.temporary_district.$error"
                                          item-text="name" item-value="id" label="District" outlined
                                          @change="getMunicipalities('temporary', pre_registration.basic_info.temporary_district)"
                                          dense hide-details>
                                    <template v-slot:label>
                                        District <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span class="text-danger"
                                      v-if="$v.pre_registration.basic_info.temporary_district.$error">This information is required</span>
                            </v-col>
                            <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.temporary_country =='nepal'">
                                <v-select
                                        v-model="pre_registration.basic_info.temporary_city"
                                        outlined dense :loading="temporaryMunicipalityLoading"
                                        :items="temporary_municipalities"
                                        item-text="name"
                                        item-value="id" :error="$v.pre_registration.basic_info.temporary_city.$error"
                                        label="Local level">
                                    <template v-slot:label>
                                        Local level <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span class="text-danger"
                                      v-if="$v.pre_registration.basic_info.temporary_city.$error">This information is required</span>
                            </v-col>
                            <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.temporary_country =='nepal'">
                                <v-select :error="$v.pre_registration.basic_info.temporary_ward.$error"
                                          v-model="pre_registration.basic_info.temporary_ward"
                                          outlined dense
                                          :items="wards"
                                          label="Ward">
                                    <template v-slot:label>
                                        Ward <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span class="text-danger"
                                      v-if="$v.pre_registration.basic_info.temporary_ward.$error">This information is required</span>
                            </v-col>
                            <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.temporary_country =='nepal'">
                                <v-text-field :error="$v.pre_registration.basic_info.temporary_area.$error"
                                              v-model="pre_registration.basic_info.temporary_area"
                                              outlined dense
                                              label="Area/tole">
                                    <template v-slot:label>
                                        Area/tole <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                                <span class="text-danger"
                                      v-if="$v.pre_registration.basic_info.temporary_area.$error">This information is required</span>
                            </v-col>
                            <v-col md="4" lg="4" cols="12" v-if="pre_registration.basic_info.temporary_country =='other'">
                                <v-text-field v-model="pre_registration.basic_info.temporary_other_address"
                                              label="address" outlined :error="$v.pre_registration.basic_info.temporary_other_address.$error"
                                              dense hide-details>
                                    <template v-slot:label>
                                        Address <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                                <span class="text-danger"
                                      v-if="$v.pre_registration.basic_info.temporary_other_address.$error">This information is required</span>

                            </v-col>

                          <v-col cols="12">
                            <h4>Parents' information <span class="text-danger">*</span></h4>
                              <hr/>
                          </v-col>
                          <v-col md="6" lg="6" cols="12">
                            <v-text-field v-model="pre_registration.basic_info.father_name" :error="$v.pre_registration.basic_info.father_name.$error"
                                          label="Father's full name" outlined dense
                                          hide-details>
                            </v-text-field>
                              <span class="text-danger" v-if="$v.pre_registration.basic_info.father_name.$error">
                                  This information is required.
                              </span>
                          </v-col>
                          <v-col md="6" lg="6" cols="12">
                            <v-text-field v-model="pre_registration.basic_info.father_contact_number"
                                          label="Father's contact number" outlined dense :error="$v.pre_registration.basic_info.father_contact_number.$error"
                                          hide-details>
                            </v-text-field>
                              <span class="text-danger" v-if="$v.pre_registration.basic_info.father_contact_number.$error">
                                  This information is required.
                              </span>
                          </v-col>
                          <v-col md="6" lg="6" cols="12">
                            <v-text-field v-model="pre_registration.basic_info.mother_name"
                                          label="Mother's full name" outlined dense  :error="$v.pre_registration.basic_info.mother_name.$error"
                                          hide-details>
                            </v-text-field>
                              <span class="text-danger" v-if="$v.pre_registration.basic_info.mother_name.$error">
                                  This information is required.
                              </span>
                          </v-col>
                          <v-col md="6" lg="6" cols="12">
                            <v-text-field :error="$v.pre_registration.basic_info.mother_contact_number.$error"
                                v-model="pre_registration.basic_info.mother_contact_number"
                                label="Mother's contact number" outlined dense hide-details>
                            </v-text-field>
                              <span class="text-danger" v-if="$v.pre_registration.basic_info.mother_contact_number.$error">
                                  This information is required.
                              </span>
                          </v-col>
                            <v-col md="6" lg="6" cols="12">
                                <v-text-field v-model="pre_registration.basic_info.guardian_name"
                                              label="Guardian full name" outlined dense :error="$v.pre_registration.basic_info.guardian_name.$error" hide-details>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.pre_registration.basic_info.guardian_name.$error">
                                  This information is required.
                              </span>
                            </v-col>
                            <v-col md="6" lg="6" cols="12">
                                <v-text-field :error="$v.pre_registration.basic_info.guardian_contact_number.$error"
                                        v-model="pre_registration.basic_info.guardian_contact_number"
                                        label="Guardian contact number" outlined dense hide-details>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.pre_registration.basic_info.guardian_contact_number.$error">
                                  This information is required.
                              </span>
                            </v-col>
                          <v-col md="4" lg="4" cols="6" class="text-left"></v-col>
                          <v-col md="12" lg="12" cols="12"  class="text-right">
                            <v-btn class="btn btn-primary btn-continue" @click="step2">
                                Next <i class="fa fa-arrow-right ml-2"></i>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-row>
                          <v-col cols="12">
                            <h4>Academic background <span class="text-danger">*</span></h4>
                              <hr/>
                          </v-col>
                          <v-col cols="12" v-for="(academic,index) in pre_registration.educational_information.academic_backgrounds" :key="index">
                            <v-row v-if="academic">
                                <v-col cols="12">
                                <strong class="text-uppercase"> {{ academic.name }}</strong>
                              </v-col>
                                <v-col md="3" lg="3" cols="12">
                                <v-text-field v-model="academic.institute_name"
                                              outlined
                                              dense hide-details>
                                    <template v-slot:label>
                                        School name <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                                <span class="text-danger" v-if="pre_registration.educational_information.academic_backgrounds.length">
                                    <span v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].institute_name.$error">
                                        School/institute name is required
                                    </span>
                                </span>
                                </v-col>
                                <v-col sm="12" md="3" lg="2" cols="12">
                                <v-select :items="completion_years"
                                          v-model="academic.completed_year"
                                          label="Completion year"
                                          outlined dense hide-details>
                                    <template v-slot:label>
                                        Completion year <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span class="text-danger" v-if="pre_registration.educational_information.academic_backgrounds.length">
                                    <span v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].completed_year.$error">
                                        Year of completion is required
                                    </span>
                                </span>
                              </v-col>
                              <v-col sm="12" md="2" lg="2" cols="12">
                                <v-text-field v-model="academic.exam_board"
                                              outlined
                                              dense hide-details>
                                    <template v-slot:label>
                                        Board/exam <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                                <span class="text-danger" v-if="pre_registration.educational_information.academic_backgrounds.length">
                                    <span v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].exam_board.$error">
                                        Exam board is required
                                    </span>
                                </span>
                              </v-col>
                              <v-col md="2" lg="2" cols="12">
                                <v-text-field v-model="academic.gpa" type="number" outlined min="0" max="4"
                                              dense
                                              hide-details>
                                    <template v-slot:label>
                                        GPA <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                                <span class="text-danger"
                                      v-if="pre_registration.educational_information.academic_backgrounds.length">
                                                <span
                                                    v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].gpa.$error">
                                                    GPA is required
                                                </span>
                                            </span>
                              </v-col>
                              <v-col md="2" lg="2" cols="12">
                                <v-text-field v-model="academic.symbol_no"  outlined dense hide-details>
                                  <template v-slot:label>
                                      <span class="text-uppercase">{{academic.name}}</span> Symbol No.
                                  </template>
                                </v-text-field>
                              </v-col>
                              <hr>
                                <v-col cols="12" lg="3" xs="12" sm="12" md="3">
                                    <v-select v-if="academic"  v-model="academic.country"
                                              :items="countries"
                                              item-text="name" item-value="value" label="Country"
                                              outlined
                                              dense hide-details>
                                        <template v-slot:label>
                                            Country <span class="text-danger">*</span>
                                        </template>
                                    </v-select>
                                    <span class="text-danger" v-if="pre_registration.educational_information.academic_backgrounds.length">
                                        <span v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].country.$error">
                                            This information is required.
                                        </span>
                                    </span>
                                </v-col>
                                <v-col cols="12" lg="3" xs="12" sm="12" md="3" v-if="academic && academic.country=='nepal'">
                                    <v-select outlined dense v-model="academic.province"  label="Province" :items="provinces" item-text="name" item-value="id" @change="getEducationalDistrictByProvince(academic.province,index, true)">
                                        <template v-slot:label>
                                            Province <span class="text-danger">*</span>
                                        </template>
                                    </v-select>
                                    <span class="text-danger" v-if="pre_registration.educational_information.academic_backgrounds.length">
                                        <span v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].province.$error">
                                            This information is required.
                                        </span>
                                    </span>
                                </v-col>
                                <v-col cols="12" lg="3" xs="12" sm="12" md="3" v-if="academic && academic.country=='nepal'" >
                                    <v-autocomplete outlined dense  v-model="academic.district"  label="District" :items="getEducationalDistricts(academic.province)" item-text="name" item-value="id"  @change="getEducationalMunicipalitiesByDistrict(academic.district,index, true)">
                                        <template v-slot:label>
                                            District <span class="text-danger">*</span>
                                        </template>
                                    </v-autocomplete>
                                    <span class="text-danger" v-if="pre_registration.educational_information.academic_backgrounds.length">
                                        <span v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].district.$error">
                                            This information is required.
                                        </span>
                                    </span>
                                </v-col>
                                <v-col cols="12" lg="3" xs="12" sm="12" md="3" v-if="academic && academic.country=='nepal'">
                                    <v-autocomplete v-model="academic.city"
                                                    outlined dense
                                                    :items="getEducationalMunicipalities(academic.district)"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Local level">
                                    </v-autocomplete>

                                </v-col>
                                <v-col cols="12" lg="3" xs="12" sm="12" md="3" v-if="academic && academic.country=='nepal'">
                                    <v-autocomplete
                                            v-model="academic.ward"
                                            outlined dense
                                            :items="wards"
                                            label="Wards">
                                    </v-autocomplete>

                                </v-col>
                                <v-col md="3" v-if="academic && academic.country=='nepal'">
                                    <v-text-field
                                            v-model="academic.area"
                                            outlined dense
                                            label="Area/Tol">
                                    </v-text-field>

                                </v-col>
                                <v-col md="6" v-if="academic && academic.country=='other'">
                                    <v-text-field
                                            v-model="academic.other_address"
                                            outlined dense
                                            label="Other address">
                                    </v-text-field>
                                    <span class="text-danger" v-if="pre_registration.educational_information.academic_backgrounds.length">
                                        <span v-if="$v.pre_registration.educational_information.academic_backgrounds.$each[index].other_address.$error">
                                            This information is required.
                                        </span>
                                    </span>
                                </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12"
                                 v-if="pre_program_details.compulsory_courses && pre_program_details.compulsory_courses.length">
                            <h4>Course choice <span class="text-danger">*</span></h4>
                              <hr/>
                            <div class="my-2">
                              <h5> Compulsory subjects</h5>
                                <p>These are subjects that you will study in this program.</p>
                            </div>
                            <div :class="$vuetify.breakpoint.mobile ? ' justify-content-around' :'justify-content-around'">
                                <span class="badge badge-course" v-for="(c_course,ic) in pre_program_details.compulsory_courses"
                                    :key="c_course">
                                {{ ic + 1 }}. {{ c_course }}
                              </span>
                            </div>
                          </v-col>
                          <v-col v-if="pre_program_details && pre_program_details.enable_selectable_course_1">
                              <div class="">
                                  <h5> Optional subjects</h5>
                                  <p>Select optional subjects that you would like to study.</p>
                              </div>
                          </v-col>
                          <v-col cols="12" v-if="pre_program_details && pre_program_details.enable_selectable_course_1 ">
                            <div class="  ">
                              <strong> Choose any ONE <span class="text-danger">*</span></strong>
                            </div>
                            <div class="ml-2">
                              <v-radio-group
                                  v-model="pre_registration.educational_information.selectable_course_1"
                                  hide-details :row="!$vuetify.breakpoint.mobile"
                                  :column="$vuetify.breakpoint.mobile">
                                <v-radio v-for="(o_course_1,ic1) in pre_program_details.selectable_course_1"
                                    :key="o_course_1" :value="o_course_1">
                                  <template v-slot:label>
                                    <div class="text-capitalize">{{ o_course_1 }}</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                              <span class="text-danger"
                                    v-if="pre_program_details && pre_program_details.selectable_course_1 ">
                                  <span v-if="$v.pre_registration.educational_information.selectable_course_1.$error">
                                          Please select optional course available that you would like to study
                                  </span>
                              </span>
                            </div>

                          </v-col>
                          <v-col cols="12"
                                 v-if="pre_program_details && pre_program_details.enable_selectable_course_2 ">
                            <div class="my-2">
                              <strong> Choose any ONE <span class="text-danger">*</span></strong>
                            </div>
                            <div class="ml-2">

                              <v-radio-group
                                  v-model="pre_registration.educational_information.selectable_course_2"
                                  hide-details row>
                                <v-radio
                                    v-for="(o_course_2,ic2) in pre_program_details.selectable_course_2"
                                    :key="o_course_2" :value="o_course_2">
                                  <template v-slot:label>
                                    <div class="text-capitalize">{{ o_course_2 }}</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                              <span class="text-danger"
                                    v-if="pre_program_details && pre_program_details.enable_selectable_course_2 ">
                                <span v-if="$v.pre_registration.educational_information.selectable_course_2.$error">
                                  Please select optional course available that you would like to study
                                </span>
                              </span>
                            </div>

                          </v-col>
                          <v-col cols="12"
                                 v-if="pre_program_details && pre_program_details.enable_selectable_course_3 ">
                            <div class="my-2">
                              <strong> Choose any ONE <span class="text-danger">*</span></strong>
                            </div>
                            <div class="ml-2">

                              <v-radio-group
                                  v-model="pre_registration.educational_information.selectable_course_3"
                                  hide-details row>
                                <v-radio
                                    v-for="(o_course_3,ic3) in pre_program_details.selectable_course_3"
                                    :key="o_course_3" :value="o_course_3">
                                  <template v-slot:label>
                                    <div class="text-capitalize">{{ o_course_3 }}</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                              <span class="text-danger" v-if="pre_program_details && pre_program_details.enable_selectable_course_3 ">
                                <span v-if="$v.pre_registration.educational_information.selectable_course_3.$error">
                                 Please select optional course available that you would like to study
                                </span>
                              </span>
                            </div>

                          </v-col>
                          <v-col cols="12"
                                 v-if="pre_program_details && pre_program_details.enable_selectable_course_4 ">
                            <div class="my-2">
                              <strong> Choose any ONE <span class="text-danger">*</span></strong>
                            </div>
                            <div class="ml-2">

                              <v-radio-group
                                  v-model="pre_registration.educational_information.selectable_course_4"
                                  hide-details row>
                                <v-radio
                                    v-for="(o_course_4,ic4) in pre_program_details.selectable_course_4"
                                    :key="o_course_4" :value="o_course_4">
                                  <template v-slot:label>
                                    <div class="text-capitalize">{{ o_course_4 }}</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                              <span class="text-danger"
                                    v-if="pre_program_details &&  pre_program_details.enable_selectable_course_4">
                                  <span
                                      v-if="$v.pre_registration.educational_information.selectable_course_4.$error">
                                         Please select optional course available that you would like to study
                                      </span>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12"
                                 v-if="pre_program_details.shifts && pre_program_details.shifts.length">
                            <h4>Shift <span class="text-danger">*</span></h4>
                              <hr/>
                              <strong>Which class timing would you prefer? </strong>
                            <div class="ml-2">
                              <v-radio-group
                                  v-model="pre_registration.educational_information.shift"
                                  hide-details row>
                                <v-radio v-for="(shifts,is) in pre_program_details.shifts"
                                         :key="shifts"
                                         :value="shifts">
                                  <template v-slot:label>
                                    <div class="text-capitalize">{{ shifts }}</div>
                                  </template>
                                </v-radio>

                              </v-radio-group>
                                <span class="text-danger"
                                      v-if="pre_program_details && pre_program_details.shifts && pre_program_details.shifts.length>0">
                                    <span
                                            v-if="$v.pre_registration.educational_information.shift.$error">
                                        Select a desired shift
                                    </span>
                                </span>
                            </div>

                          </v-col>
                          <v-col cols="12" v-if="entrances && entrances.length > 0">
                            <h4>Entrance date <span class="text-danger">*</span></h4>
                            <hr>


                            <div class="ml-2 entrance-date" v-for="(entrance,en) in entrances"
                                 :key="en">
                              <span class="font-size-lg font-weight-bold">{{entrances[en][0].date}}</span>
                              <v-radio-group
                                  v-model="entrance_date"
                                  hide-details row>
                                <v-radio v-for="(ent,ei) in entrance"
                                         :value="ent.idx" :key="ei" >
                                  <template v-slot:label>
                                    <div class="text-capitalize">{{ ent.display_text }}
                               </div>
                                  </template>
                                </v-radio>


                              </v-radio-group>
                            </div>
                            <div class="text-danger" style="clear: both">
                              <span v-if="$v.pre_registration.educational_information.entrance_date_time.$error">
                                Please select a date and time of entrance exam
                              </span>
                            </div>

                          </v-col>
                          <v-col cols="12" v-if="entrances && entrances.length == 0">
                              <span class="text-danger">
                              <span v-if="$v.pre_registration.educational_information.entrance_date_time.$error">
                             Sorry no entrance date and time is currently available to submit application. We will let you know when new entrance date is available.
                              </span>
                            </span>
                          </v-col>
                        </v-row>
                          <v-row>
                          <v-col cols="12" sm="12" md="4" lg="4"  class="order-last order-md-first text-left">
                              <v-btn
                                      class="btn btn-previous mr-5 pull-left"
                                      @click="previousStepForOne"
                              >
                                  <i class="fa fa-arrow-left"></i> Previous
                              </v-btn>
                          </v-col>
                          <v-col cols="12" sm="12" md=8 lg="8"  class="text-right">
                            <v-btn
                                class="btn btn-primary btn-continue text-white"
                                @click="step3"
                            >
                              Next <i class="fa fa-arrow-right ml-2"></i>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        <v-row>
                          <v-col md="4" lg="4" cols="12" v-if="pre_program_details.enable_hostel_availability">
                              <b>Hostel facility</b>
                          </v-col>
                          <v-col cols="8" v-if="pre_program_details.enable_hostel_availability">
                            <v-radio-group
                                v-model="pre_registration.other_information.hostel_facility"
                                row
                                hide-details>
                              <v-radio :value="true">
                                <template v-slot:label>
                                  <div>Yes</div>
                                </template>
                              </v-radio>
                              <v-radio :value="false">
                                <template v-slot:label>
                                  <div>No</div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                            <span class="text-danger"
                                  v-if="$v.pre_registration.other_information.hostel_facility.$error">Please select one</span>
                          </v-col>
                          <v-col md="4" lg="4" cols="12"
                                 v-if="pre_program_details.enable_transportation_facility">
                              <b>Transportation facility</b>
                          </v-col>
                          <v-col md="4" lg="4" cols="12"
                                 v-if="pre_program_details.enable_transportation_facility">
                            <v-radio-group
                                v-model="pre_registration.other_information.transportation_facility"
                                row hide-details>
                              <v-radio :value="true">
                                <template v-slot:label>
                                  <div>Yes</div>
                                </template>
                              </v-radio>
                              <v-radio :value="false">
                                <template v-slot:label>
                                  <div>No</div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                            <span class="text-danger"
                                  v-if="$v.pre_registration.other_information.transportation_facility.$error">Please Select One</span>
                          </v-col>
                          <v-col md="4" lg="4" cols="12" v-if="pre_registration.other_information && pre_registration.other_information.transportation_facility">
                            <v-text-field label="Pickup location" outlined dense v-model="pre_registration.other_information.pickup_location" :error="$v.pre_registration.other_information.pickup_location.$error">
                            </v-text-field>
                            <span class="text-danger"
                                  v-if="$v.pre_registration.other_information.pickup_location.$error">This information is required.</span>
                          </v-col>
                          <v-col cols="12">
                              <h4>How did you come to know about us?</h4>
                              <hr/>
                          </v-col>
                          <v-col cols="12"
                                 v-if="pre_program_details.recommended_options && pre_program_details.recommended_options.length">
                            <v-row>
                              <v-col md="6" lg="6" cols="12"
                                     v-for=" (option_recomended, op) in pre_program_details.recommended_options"
                                     :key="op">
                                <v-checkbox
                                    :key="option_recomended"
                                    v-model="pre_registration.other_information.recomended_sources"
                                    hide-details
                                    :label="option_recomended"
                                    :value="option_recomended"

                                ></v-checkbox>
                              </v-col>
                            </v-row>
                              <span class="text-danger" v-if="$v.pre_registration.other_information.recomended_sources.$error">This information is required.</span>
                          </v-col>

                          <v-col cols="12">
                            <h6> Upload your passport size photo (If available)</h6><hr/>
                            <v-file-input
                                show-size
                                counter
                                outlined
                                dense
                                v-model="pre_registration.other_information.photo"
                                label="Upload photo"
                            ></v-file-input>
<!--                            <span class="text-danger"-->
<!--                                  v-if="$v.pre_registration.other_information.photo.$error">Please upload passport size photo of yours</span>-->
                          </v-col>
                          <v-col cols="12">
                            <h6> Grade/Marksheet of Send-up/Latest Grade X/SEE/Equivalent
                              certification (If
                              available)</h6><hr/>
                            <v-file-input
                                show-size
                                counter
                                outlined
                                dense
                                v-model="pre_registration.other_information.marksheet"
                                label="Upload certificate"
                            ></v-file-input>
                            <!--                            <span class="text-danger"-->
                            <!--                                  v-if="$v.pre_registration.other_information.marksheet.$error">At least one Certificate is required.</span>-->
                          </v-col>
                          <v-col cols="12">
                            <h6> Additional documents</h6><hr/>
                            <!--                            <pre>-->
                            <!--                              {{pre_registration.other_information}}-->
                            <!--                            </pre>-->
                            <v-row v-for="(document,index) in pre_registration.other_information.additional_documents"
                                   :key="index">
                              <v-col sm="12" md="3" lg="3" cols="12">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Title"
                                    v-model="document.name"
                                >

                                </v-text-field>

                              </v-col>
                              <v-col md="6" lg="6" cols="12">
                                <v-file-input
                                    show-size
                                    counter
                                    outlined
                                    dense
                                    v-model="document.file"
                                    label="Upload certificate"
                                ></v-file-input>
                              </v-col>
                              <v-col sm="12" md="3" lg="3" cols="12">
                                <v-btn class="btn  btn-next"
                                       v-if="index == pre_registration.other_information.additional_documents.length-1"
                                       outlined @click.prevent="add_additional_documents"> <i class="fa fa-plus"></i> Add file
                                </v-btn>
                                <v-btn class="btn btn-cancel" v-else outlined
                                       @click.prevent="remove_additional_documents(index)"> <i class="fa fa-trash"></i>Remove
                                </v-btn>
                              </v-col>
                            </v-row>

                          </v-col>

                          <v-col cols="12">
                              <h4>Declaration <span class="text-danger">*</span></h4><hr/>
                              <div>
                                  <v-checkbox  :error="$v.pre_registration.other_information.declaration.$error" v-model="pre_registration.other_information.declaration">
                                              hide-details>
                                      <template v-slot:label>
                                          <div>
                                              I declare the information supplied in this application and the documentation supporting it is correct and complete.
                                          </div>
                                      </template>
                                  </v-checkbox>
                              </div>
                              <div>
                                  <v-checkbox :error="$v.pre_registration.other_information.declaration2.$error" v-model="pre_registration.other_information.declaration2"
                                              hide-details>
                                      <template v-slot:label>
                                          <div>
                                              I acknowledge that the provision of false or misleading information may result in the non-acceptance of this application or immediate expulsion from the college after admission
                                          </div>
                                      </template>
                                  </v-checkbox>
                              </div>
                              <div>
                                  <v-checkbox :error="$v.pre_registration.other_information.declaration3.$error" v-model="pre_registration.other_information.declaration3"
                                              hide-details>
                                      <template v-slot:label>
                                          <div>
                                              I authorize the college to verify any information provided by me.
                                          </div>
                                      </template>
                                  </v-checkbox>
                              </div>
                              <div>
                                  <v-checkbox :error="$v.pre_registration.other_information.declaration4.$error" v-model="pre_registration.other_information.declaration4"
                                              hide-details>
                                      <template v-slot:label>
                                          <div>
                                              I authorize the college to obtain, where necessary, from referee or/and from any other educational institution evidence of my academic record or to seek other supporting evidence with respect to my application.
                                          </div>
                                      </template>
                                  </v-checkbox>
                              </div>
                          </v-col>
                        </v-row>
                          <v-row>
                          <v-col cols="12" sm="12" md="4" lg="4"  class="order-last order-md-first text-left">
                            <v-btn
                                class="btn btn-previous"
                                @click="previousStepForTwo"
                            >
                              <i class="fa fa-arrow-left"></i> Previous
                            </v-btn>
                          </v-col>

                          <v-col cols="12" sm="12" md=8 lg="8"  class="text-right">
                            <v-btn :disabled="!pre_registration.other_information.declaration || !pre_registration.other_information.declaration2 || !pre_registration.other_information.declaration3 || !pre_registration.other_information.declaration4"
                                class="btn btn-primary btn-next text-white"
                                @click="submit"
                            >
                              Proceed to payment
                            </v-btn>
                          </v-col>

                        </v-row>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>

              <v-card class="" >
                <v-card-title class="d-flex justify-center">
                 <p v-html="online_admission_closed_content"></p>
                  <v-col cols="12" class="text-right">
                    <v-btn @click.prevent="redirectTo" depressed class="btn btn-primary ml-2" dark>
                      Back
                    </v-btn>
                  </v-col>
                </v-card-title>
              </v-card>

            </v-container>

          </template>

        </v-app>
      </div>
    </div>
  </div>

</template>

<script>
import {email, required,requiredIf} from "vuelidate/lib/validators";
import StepperMixin from "@/mixins/StepperMixin";
import VueRecaptcha from "vue-recaptcha";
import OptionService from "@/core/services/option/OptionService";
import enrollment from "@/core/services/store/enrollment";

const isPhone = (value) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
const optionService = new OptionService();

export default {
  name: "Index",
  mixins: [StepperMixin],
  components: {VueRecaptcha},
  props: ['level', 'program', 'setting'],

  validations() {
    return {
      basicInfo: {
        first_name: {required},
        last_name: {required},
        email: {required},
        phone: {required, isPhone},
        recaptcha: {required},
      },
      pre_registration: {
        basic_info: this.basic_info_rule,
        educational_information: this.educational_information_rule,
        other_information: this.other_information_rule,
      },
      dob_bs_year: {
        required: requiredIf(function() {
          return !this.dob_in_ad
        })
      },
      dob_bs_month:{
        required: requiredIf(function() {
          return !this.dob_in_ad
        })
      },
      dob_bs_day:{
        required: requiredIf(function() {
          return !this.dob_in_ad
        })
      },
    }

  },
  data() {
    return {
        copy_permanent_address:false,
      online_admission_closed_content:null,
      entrances: [],
      step: 1,
      attrs: {},
      menu2: false,
      dob_in_ad: false,
      show: true,
      date: null,
      dob_ad: null,
      dob_bs:null,
      /*dob_bs_year:'',
      dob_bs_month:'',
      dob_bs_day:'',*/
      site_main_url: process.env.VUE_APP_SERVER_URL,
      currentDate: new Date().toISOString(),
      gender: [
        {name: 'Male', value: 'male'},
        {name: 'Female', value: 'female'},
        {name: 'Other', value: 'other'},
      ],
      countries: [
        {name: 'Nepalese', value: 'nepal'},
        {name: 'Non-nepalese', value: 'other'},
      ],
      years:[],
      months:[],
      days:[],
      basicInfo: {
        pre_registration_id: null,
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        level: null,
        program: null,
        dob: null,
        dob_bs: null,
        gender: null,
        nationality: null,
        more_details: null,
        recaptcha: null,
        entrance_date_time: null
      },
      pre_registration: {
        basic_info: {
          full_name: null,
          email: null,
          phone: null,
          nationality: 'nepal',
          dob: null,
          dob_bs: null,
          gender: null,
          country: 'nepal',
          province: null,
          district: null,
          father_name: null,
          mother_name: null,
            guardian_name: null,
            father_contact_number: null,
          mother_contact_number: null,
            guardian_contact_number: null,
          parents_contact_number: null,
          temporary_country:'nepal',
          temporary_province:null,
        },
        educational_information: {
          academic_backgrounds: [],
          compulsory_subjects : null,
          selectable_course_1: null,
          selectable_course_2: null,
          selectable_course_3: null,
          selectable_course_4: null,
          shift: null,
          entrance_date_time: null
        },
        other_information: {
          hostel_facility: false,
          transportation_facility: false,
          recomended_sources: [],
          additional_documents: [
            {name: 'Other', file: null}
          ],
          photo: null,
          marksheet: null,
          declaration: false,
          declaration2: false,
          declaration3: false,
          declaration4: false,
        }
      },
      awards_and_eca:{
        rewards_and_certificates:null,
        club_involvement:null,
        has_volunteered_organization:false,
        volunteered_organization_text:null,
        future_plans_text:null,
        have_awarded_scholarship:false,
        scholarship_text:null,
      },
      additional_information:{
        has_applicant_repeated_class:false,
        applicant_repeated_class_text:null,
        has_applicant_suspended:null,
        applicant_suspended_text:null,
        does_applicant_have_mental:false,
        applicant_have_mental_text:false,
        does_applicant_take_medication:false,
        applicant_take_medication_text:null,
        does_applicant_special_needs:false,
        applicant_special_needs_text:null,
      },

    }
  },
  computed: {

    token() {
      let token = localStorage.getItem('tk')
      return token ? token : false
    },

    sitekey :function ()
    {
      return  this.$store.getters.siteKey;
    },

    basic_info_rule() {
      let rule = {
        full_name: {required},
        email: {required},
        phone: {required, isPhone},
        nationality: {required},
        gender: {required},
        country: {required},
          temporary_country: {required},
          father_name: {
              required: requiredIf(function() {
                  return !this.pre_registration.basic_info.mother_name && !this.pre_registration.basic_info.guardian_name
              })
          },
          father_contact_number: {
              required: requiredIf(function() {
                  return !this.pre_registration.basic_info.mother_contact_number && !this.pre_registration.basic_info.guardian_contact_number
              })
          },
          mother_name: {
              required: requiredIf(function() {
                  return !this.pre_registration.basic_info.father_name && !this.pre_registration.basic_info.guardian_name
              })
          },
          mother_contact_number: {
              required: requiredIf(function() {
                  return !this.pre_registration.basic_info.father_contact_number && !this.pre_registration.basic_info.guardian_contact_number
              })
          },
          guardian_name: {
              required: requiredIf(function() {
                  return !this.pre_registration.basic_info.father_name && !this.pre_registration.basic_info.mother_name
              })
          },
          guardian_contact_number: {
              required: requiredIf(function() {
                  return !this.pre_registration.basic_info.father_contact_number && !this.pre_registration.basic_info.mother_contact_number
              })
          },
      }
      if (this.dob_in_ad) {
        rule.dob = {required}
      } else {
        rule.dob_bs = {required}
      }
      if(this.pre_registration.basic_info.country=='nepal'){
          rule.province={required};
          rule.district={required};
          rule.city={required};
          rule.ward={required};
          rule.area={required};
      }else{
          rule.other_address={required};
      }
      if(this.pre_registration.basic_info.temporary_country=='nepal'){
            rule.temporary_province={required};
            rule.temporary_district={required};
            rule.temporary_city={required};
            rule.temporary_ward={required};
            rule.temporary_area={required};
        }else{
            rule.temporary_other_address={required};
        }
      return rule;

    },
    educational_information_rule() {
      let rule = {}
      rule.entrance_date_time = {required}
      if (this.pre_program_details && this.pre_program_details.enable_selectable_course_1) {
        rule.selectable_course_1 = {required}
      }
      if (this.pre_program_details && this.pre_program_details.enable_selectable_course_2) {
        rule.selectable_course_2 = {required}
      }
      if (this.pre_program_details && this.pre_program_details.enable_selectable_course_3) {
        rule.selectable_course_3 = {required}
      }
      if (this.pre_program_details && this.pre_program_details.enable_selectable_course_4) {
        rule.selectable_course_4 = {required}
      }
      if (this.pre_program_details && this.pre_program_details.shifts && this.pre_program_details.shifts.length) {
        rule.shift = {required}
      }
      if (this.pre_registration.educational_information.academic_backgrounds.length > 0) {
        rule.academic_backgrounds = {
          $each: {
            institute_name: {required},
            completed_year: {required},
            exam_board: {required},
            gpa: {required},
            country: {required},
            province: {
                requiredIf: requiredIf(function (value) {
                    return value.country === 'nepal';
                }),
            },
            district: {
                  requiredIf: requiredIf(function (value) {
                      return value.country === 'nepal';
                  }),
            },

              other_address: {
                  requiredIf: requiredIf(function (value) {
                      return value.country === 'other';
                  }),
            },
          }
        }
      }
      return rule;
    },
    other_information_rule() {
      let rule = {}
        // photo: {required},
        // marksheet: {required},
      if (this.pre_program_details && this.pre_program_details.recommended_options && this.pre_program_details.recommended_options.length > 0) {
         rule.recomended_sources = {required}
      }
      if (this.pre_program_details && this.pre_program_details.enable_hostel_availability) {
        rule.hostel_facility = {required}
      }
      if (this.pre_program_details && this.pre_program_details.enable_declaration) {
        rule.declaration = {required}
      }
      if (this.pre_program_details && this.pre_program_details.enable_transportation_facility) {
        rule.transportation_facility = {required}
      }
      if(this.pre_registration && this.pre_registration.other_information.transportation_facility){
        rule.pickup_location = {required}
      }
        rule.declaration= {checked: value => value === true};
        rule.declaration2= {checked: value => value === true};
        rule.declaration3= {checked: value => value === true};
        rule.declaration4= {checked: value => value === true};


      return rule;
    },
    completion_years() {
      let years = [];
      let thisYear = this.$moment().format("YYYY")
      for (let i = 0; i <= 15; i++) {
        years.push(thisYear - i);
      }
      return years;
    },
    wards() {
      let start = 1;
      let wards = [];
      for (start; start < 33; start++) {
        wards.push(start);
      }
      return wards
    },
    applicationId() {
      return this.$route.query.application_id;
    },
  },
  mounted() {

    this.basicInfo.email = localStorage.getItem('em');
    if(this.level){
      this.basicInfo.level = this.level;
      this.basicInfo.level_id = this.level;
    }
    if(this.program){
      this.basicInfo.program = this.program;
      this.basicInfo.program_id = this.program;
    }
    this.getYears();
    this.getMonths();
    this.getDays();
    this.onAdChange()
    this.getAllPreRegistrationPrograms(this.level);
    this.getAllPreRegistrationDetail(this.level, this.program);
    this.getAllPreRegistrationLevels()
    this.getProvinces()
    this.isPreRegistrationActive();
    this.getEnrollmentByToken();
      if(!this.$route.query.application_id || this.$route.query.application_id=='undefined' || this.$route.query.application_id==null){
          if (localStorage.hasOwnProperty('tk')) {
              localStorage.removeItem('tk');
          }
          this.$router.push({name: 'pre-registration'});

      }else{
          this.goToAdmission();
      }
    this.getByOnlineAdmissionClosedContent();
  },
  created() {


  },
  methods: {
      getByOnlineAdmissionClosedContent(){
          optionService
              .getByKey('ONLINE_ADMISSION_CLOSED_CONTENT')
              .then(response => {
                  if(response.data){
                      this.online_admission_closed_content = response.data.value;
                  }


              }).catch((err) => {
          }).finally(() => {


          });
      },
    /*checkRecaptcha() {
      this.$refs.invisibleRecaptcha.execute();
    },*/

    goToAdmission() {
      const currentPath = this.$route.path;
      const targetPath = `/admission?application_id=${this.applicationId}`;

      if (currentPath !== targetPath) {
        this.$router.push(targetPath);
      }
    },
    step2() {
      this.scrollup();

      this.pre_registration.dob_bs = this.dob_bs;

      this.pre_registration.dob = this.dob_ad;
      this.pre_registration.basic_info.dob_bs = this.dob_bs;
      this.pre_registration.basic_info.dob = this.dob_ad;
      this.$v.pre_registration.basic_info.$touch();
      this.$v.dob_bs_year.$touch();
      this.$v.dob_bs_month.$touch();
      this.$v.dob_bs_day.$touch();
      if (this.$v.pre_registration.basic_info.$error || this.$v.dob_bs_year.$error || this.$v.dob_bs_month.$error || this.$v.dob_bs_day.$error) {
        setTimeout(() => {
          this.$v.pre_registration.basic_info.$reset();
          this.$v.pre_registration.dob_bs_year.$reset();
          this.$v.pre_registration.dob_bs_month.$reset();
          this.$v.pre_registration.dob_bs_day.$reset();
        }, 15000);
      } else {
        this.enrollment.dob = this.pre_registration.basic_info.dob;
        this.enrollment.dob_bs = this.pre_registration.basic_info.dob_bs;
        this.enrollment.gender = this.pre_registration.basic_info.gender;
        this.enrollment.nationality = this.pre_registration.basic_info.nationality;
        this.enrollment.more_details = this.pre_registration;
        this.enrollment.step = 'first';
        this.updateEnrollment(this.enrollment,this.applicationId);
        if(this.pre_registration_id){
            this.getEntrances(this.pre_registration_id);
        }
        this.step = 2;

      }
    },
    previousStepForOne(){
      this.step=1;
      this.enrollment.step = 'first';
    },
    previousStepForTwo(){
      this.step=2;
      this.enrollment.step = 'second';
    },
    step3() {
      this.scrollup()
      if(this.entrance_date){
        this.pre_registration.educational_information.entrance_date_time=this.entrance_date;
        this.enrollment.entrance_exam_id=this.entrance_date;
      }else{
        this.pre_registration.educational_information.entrance_date_time=null;
        this.enrollment.entrance_exam_id=null;
      }
      this.$v.pre_registration.educational_information.$touch();
      if (this.$v.pre_registration.educational_information.$error) {
          setTimeout(() => {
          this.$v.pre_registration.educational_information.$reset();
        }, 15000);
      } else {
        this.enrollment.step = 'second';
        if(this.entrance_exam_validation_flag){
          this.$snotify.error("Entrance exam required");
        }else{
          this.step = 3;
        }
        this.enrollment.more_details = this.pre_registration;

        this.updateEnrollment(this.enrollment,this.applicationId)
        this.getEnrollmentById(this.enrollment.id);


      }
    },
    submit() {

      this.enrollment.pre_registration_id = this.pre_registration_id
      this.basicInfo.pre_registration_id = this.pre_registration_id;
      //this.basicInfo['entrance_date_time'] = this.pre_registration.educational_information.entrance_date_time;
      this.basicInfo.submission_type = "online";
      this.$v.pre_registration.other_information.$touch();
      if (this.$v.pre_registration.other_information.$error) {
        setTimeout(() => {
          this.$v.pre_registration.other_information.$reset();
        }, 15000);
      } else {
        this.enrollment.step = 'third';

        if (this.pre_registration.other_information.photo || this.pre_registration.other_information.marksheet) {
          this.upload()
        }
        this.enrollment.generate_and_mail_invoice = 0;
        this.enrollment.is_submitted = true;
        this.enrollment.exam_status = 'active';
        this.enrollment.registration_status = 'active';
        this.enrollment.more_details = this.pre_registration;
        this.enrollment.completed_status='complete';


        this.updateEnrollment(this.enrollment,this.applicationId);
      }
    },
    submittedProcess(){

      if(this.entrance_exam_validation_flag){
        this.$snotify.error("Entrance exam required");
        this.step=2;
      }else{
        this.getEnrollmentById(this.enrollment.id)
        this.$snotify.success("Pre-registration information saved");
        window.localStorage.setItem('_newToken',this.applicationId);
        this.$router.push({name: 'success'});
      }
    },
    scrollup() {
        window.scrollTo(0, 0);
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.basicInfo.recaptcha = true;
      if (!this.$v.basicInfo.$anyError) {
        // set spinner to submit button

      }
    },

    onSubmit() {
      // this.$refs.invisibleRecaptcha.execute();
      this.$v.basicInfo.$touch();
      if (this.$v.basicInfo.$anyError) {
        return;
      }
    },

    redirectTo() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.$router.push({name: 'pre-registration'});
          }
        }
      });
    },

    upload() {
      let fd = new FormData();
      if (this.pre_registration.other_information.photo) {
        fd.append('image[]', this.pre_registration.other_information.photo);
        fd.append('type[]', 'photo');

        // delete this.this.pre_registration.other_information.photo;
      }
      if (this.pre_registration.other_information.marksheet) {
        fd.append('image[]', this.pre_registration.other_information.marksheet);
        fd.append('type[]', 'Marksheet');
        // delete this.pre_registration.other_information.marksheet
      }
      if (this.pre_registration.other_information.additional_documents.length) {
        this.pre_registration.other_information.additional_documents.forEach(document => {
          if(document.file) {
            fd.append('image[]', document.file);
            fd.append('type[]', document.name);
          }
        })

      }
      this.uploadDocument(this.enrollment.id, fd)
    },
      add_additional_documents() {
      this.pre_registration.other_information.additional_documents.push(
          {
            name: 'Other',
            file: null,
          }
      );
    },
    remove_additional_documents(index) {
      this.pre_registration.other_information.additional_documents.splice(index, 1);
    },
    changeDOBFormat(){
          this.dob_bs=null;
          this.dob_ad=null;
        this.dob_bs_year=null;
        this.dob_bs_month=null;
        this.dob_bs_day=null;
        this.pre_registration.dob_bs=null;
        this.pre_registration.dob=null;
    },
    buildDateOfBirth()
    {
      if (this.dob_bs_year!='' && this.dob_bs_month!='' && this.dob_bs_day!='')
      {
          this.dob_bs = this.dob_bs_year+'-'+this.dob_bs_month+'-'+this.dob_bs_day;
          if(this.dob_bs){
            this.onBsChange();
        }
      }

    },

    onAdChange() {
      this.dob_bs = null;
      optionService.convertToBs(this.dob_ad).then(response => {

        this.dob_bs = response.data
      })
    },
    onBsChange() {
      optionService.convertToAd(this.dob_bs).then(response => {
          this.dob_ad = response.data;
      })
    },
      changeTemporaryAddress(){
          if(this.copy_permanent_address){
              if(this.pre_registration.basic_info.country){
                  this.pre_registration.basic_info.temporary_province=this.pre_registration.basic_info.province;
                  this.getDistricts('temporary',this.pre_registration.basic_info.temporary_province);
              }
              if(this.pre_registration.basic_info.district){
                  this.pre_registration.basic_info.temporary_district=this.pre_registration.basic_info.district;
                  this.getMunicipalities('temporary',this.pre_registration.basic_info.temporary_district);
              }
              if(this.pre_registration.basic_info.city){
                this.pre_registration.basic_info.temporary_city=this.pre_registration.basic_info.city;
              }
              if(this.pre_registration.basic_info.ward){
                  this.pre_registration.basic_info.temporary_ward=this.pre_registration.basic_info.ward;
              }
              if(this.pre_registration.basic_info.area){
                   this.pre_registration.basic_info.temporary_area=this.pre_registration.basic_info.area;
              }
              if(this.pre_registration.basic_info.other_address){
                   this.pre_registration.basic_info.temporary_other_address=this.pre_registration.basic_info.other_address;
              }
          }else{
              this.pre_registration.basic_info.country='nepal';
              this.pre_registration.basic_info.temporary_province=null;
              this.pre_registration.basic_info.temporary_district=null;
              this.pre_registration.basic_info.temporary_city=null;
              this.pre_registration.basic_info.temporary_ward=null;
              this.pre_registration.basic_info.temporary_area=null;
              this.pre_registration.basic_info.temporary_other_address=null;
              this.pre_registration.basic_info.other_address=null;
              this.temporary_districts=[];
              this.temporary_municipalities=[];

          }
      },
  },

}
</script>

<style scoped>
.v-stepper__header {
  box-shadow: none;
}

.v-stepper__label {
  border-bottom: 1px solid black !important;
}
</style>
