<template>
  <v-app>
      <v-container class="main-form">
          <div class="row">
              <div
                      class="col-md-12 bg-white"
                      id="kt_login" style="min-height: 100vh;"
              >


                  <!--begin::Content-->
                  <div v-if="isBusy"
                       class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden"
                  >
                      <div class="d-flex flex-column-fluid flex-center mt-lg-10 mt-lg-0">
                          <div class="d-flex flex-column align-items-center align-center text-center ">

                              <v-skeleton-loader
                                      v-bind="attrs"
                                      width="500"
                                      type="list-item-three-line"

                              ></v-skeleton-loader>
                          </div>
                      </div>
                  </div>

                  <template v-if="!isBusy">
                      <div  v-if="selectedProgram && selectedLevel">
                          <admission-first-step v-if="!applicationId" :level="selectedLevel"  :program="selectedProgram" :setting="setting"  @changeProgram="getProgramDetail($event)">
                          </admission-first-step>
                          <pre-registration v-if="applicationId"  :level="selectedLevel" :program="selectedProgram" :setting="setting"
                                            @changeProgram="getProgramDetail($event)"></pre-registration>
                      </div>
                      <div v-else class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden">

                          <div class="d-flex flex-column-fluid flex-center mt-lg-10 mt-lg-0">
                              <div class="d-flex flex-column align-items-center align-center text-center ">

                                  <!--                            <h2 class="mb-5 ">Select your interest to continue </h2>-->
                                  <template v-if="pre_registration_active">
                                      <template v-if="!selectedLevel">
                                          <h1 class="mb-5 font-weight-bolder">Select level to continue </h1>
                                          <div v-for="(level,levelIndex) in levels" :key="levelIndex">

                                              <v-btn @click.prevent="selectLevel(level.idx)" depressed
                                                     class="btn btn-primary rounded-pill headline text-white min-w-250px mt-2">
                                                  {{ level.display_name }}
                                              </v-btn>
                                          </div>
                                          <!--                    <div>-->

                                          <!--                      <v-btn v-if="pending_application_id" depressed @click="openPendingApplication()"-->
                                          <!--                             class="btn btn-primary rounded-pill headline text-white min-w-250px mt-2">-->
                                          <!--                        Continue Application-->
                                          <!--                      </v-btn>-->
                                          <!--                    </div>-->
                                          <v-btn @click.prevent="redirectTo" depressed
                                                 class="btn btn-previous rounded-pill headline min-w-250px mt-2">
                                              <i class="fa fa-arrow-left"></i> Back
                                          </v-btn>
                                      </template>
                                      <template v-else>
                                          <template v-if="isBusy">
                                              <v-skeleton-loader
                                                      v-bind="attrs"
                                                      type="list-item-three-line"
                                              ></v-skeleton-loader>
                                          </template>
                                          <template>
                                              <h1 class="mb-5 font-weight-bolder">Select program to continue </h1>

                                              <div v-for="(program,programIndex) in programs" :key="programIndex">
                                                  <v-btn @click.prevent="selectProgram(program.idx)" depressed
                                                         class="btn btn-primary rounded-pill headline text-white min-w-250px mt-2">
                                                      {{ program.display_name }}
                                                  </v-btn>
                                              </div>
                                              <v-btn @click.prevent="redirectTo" depressed
                                                     class="btn btn-previous rounded-pill headline min-w-250px mt-2">
                                                  <i class="fa fa-arrow-left"></i> Back
                                              </v-btn>
                                          </template>
                                      </template>
                                  </template>
                                  <template v-if="!pre_registration_active">
                                      <v-card class="" outlined>
                                          <v-card-title class="d-flex justify-center">
                                            <p v-html="online_admission_closed_content"></p>
                                              <v-col cols="12" class="text-right">
                                                  <v-btn @click.prevent="redirectTo" depressed class="btn btn-cancel ml-2">
                                                      Back
                                                  </v-btn>
                                              </v-col>
                                          </v-card-title>
                                      </v-card>
                                  </template>


                              </div>
                          </div>
                      </div>
                  </template>


                  <!--end::Content-->
              </div>
          </div>
      </v-container>


    <v-container fuild class="mt-5 bg-white" v-if="program_details.length>0">
      <div class=" p-5 text-center">
        <h3>Admission Process</h3>

      </div>
      <v-expansion-panels focusable flat>
        <v-expansion-panel
            v-for="(item,i) in program_details"
            :key="i"
            class="mb-3"
        >
          <v-expansion-panel-header :color="login_bg_color" class="text-white ">
            {{ item.title }}

          </v-expansion-panel-header>
          <v-expansion-panel-content class="p-5 d-flex flex-column" v-html="item.description">

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <vue-snotify></vue-snotify>
    <vue-confirm-dialog></vue-confirm-dialog>
    <loading></loading>
  </v-app>

</template>

<!-- Load login custom page styles -->

<script>
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
import {mapState} from "vuex";
import PreRegistration from "../front-end/pre-registration/Index"
import EssentialService from "@/core/services/enrollment/EssentialService";
import StepperMixin from "@/mixins/StepperMixin";
import Loading from "../layout/Loading";
import AdmissionFirstStep from "@/view/front-end/pre-registration/AdmissionFirstStep.vue";
import OptionService from "@/core/services/option/OptionService";

const option=new OptionService();
const siteSettingService = new SiteSettingService();
const enrollmentEssentialService = new EssentialService();
export default {
  name: "base",
  mixins: [StepperMixin],
  components: {Loading, PreRegistration,AdmissionFirstStep},
  data() {
    return {
      pending_application_id:1,
      attrs: {},
      selectedLevel: null,
      selectedProgram: null,
      setting: null,
      login_bg_path: null,
      login_bg_color: null,
      college_color: null,
      slogan_color: null,
      logo_path: null,
      program_details: [],
      online_admission_closed_content:null

    }
  },
  methods: {
    redirectTo() {
      this.$router.push({name: 'index'});
    },
    getProgramDetail(event = null) {
      enrollmentEssentialService
          .getProgramDetail(this.selectedLevel, this.selectedProgram)
          .then(response => {
        this.program_details = response.data.details;
      })
    },
    selectLevel(level) {
      this.selectedLevel = level;
      this.getAllPreRegistrationPrograms(level);
    },
    selectProgram(program) {
      this.selectedProgram = program;
      this.getAllPreRegistrationDetail(this.selectedLevel, this.selectedProgram);
      let data = {
        level: this.selectedLevel,
        program: this.selectedProgram,
      }
      this.getProgramDetail()
    },
    getColors() {
      siteSettingService.getColorsVariables().then((response) => {
        this.setting = response.data;
        if (this.setting && this.setting.id) {
          if (this.setting.colors_variables) {
            this.colors_variables = JSON.parse(this.setting.colors_variables);
            this.setStyleVariable();
          }
          if (this.setting.login_bg_path && this.setting.login_bg_path['real']) {
            this.login_bg_path = this.setting.login_bg_path['real'];
          }
          if (this.setting.logo_path && this.setting.logo_path['real']) {
            this.logo_path = this.setting.logo_path['real'];
          }
          if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
            this.login_bg_color = this.setting.colors_variables_json_values['login']['bg_color'];
          }
          if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
            this.college_color = this.setting.colors_variables_json_values['login']['primary_color'];
          }
          if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
            this.slogan_color = this.setting.colors_variables_json_values['login']['secondary_color'];
          }
          if (this.setting) {
            this.handleIcon()
          }

        }
      }).catch(err => {
      })
    },
    handleIcon() {
      // accessing the link tag
      if (this.setting.fav_icon_path && this.setting.fav_icon_path['real']) {
        const favicon = document.getElementById("favicon");
        favicon.href = this.setting.fav_icon_path['real'];
      }
      document.title = this.setting.tagline || 'KIST Online Admission System';
    },
    setStyleVariable() {

      document.documentElement.style.setProperty('--sidebar-primary-color', this.colors_variables.side_bar.primary_color);
      document.documentElement.style.setProperty('--sidebar-secondary-color', this.colors_variables.side_bar.secondary_color);
      document.documentElement.style.setProperty('--sidebar-primary-text-color', this.colors_variables.side_bar.primary_text_color);
      document.documentElement.style.setProperty('--sidebar-secondary-text-color', this.colors_variables.side_bar.secondary_text_color);

      document.documentElement.style.setProperty('--header-primary-color', this.colors_variables.header.primary_color);
      document.documentElement.style.setProperty('--header-secondary-color', this.colors_variables.header.secondary_color);
      document.documentElement.style.setProperty('--header-primary-text-color', this.colors_variables.header.primary_text_color);
      document.documentElement.style.setProperty('--header-secondary-text-color', this.colors_variables.header.secondary_text_color);

      document.documentElement.style.setProperty('--button-primary-color', this.colors_variables.button.primary_color);
      document.documentElement.style.setProperty('--button-secondary-color', this.colors_variables.button.secondary_color);
      document.documentElement.style.setProperty('--button-primary-text-color', this.colors_variables.button.primary_text_color);
      document.documentElement.style.setProperty('--button-secondary-text-color', this.colors_variables.button.secondary_text_color);
      document.documentElement.style.setProperty('--login-bg-color', this.colors_variables.login.bg_color);

    },
    openPendingApplication() {
      let url = window.location.href;
      url = url + "?application_id" + pending_application_id
      window.location.href = url;
    },
    getByOnlineAdmissionClosedContent(){
        option
            .getByKey('ONLINE_ADMISSION_CLOSED_CONTENT')
            .then(response => {
                if(response.data){
                    this.online_admission_closed_content = response.data.value;
                }


            }).catch((err) => {
            }).finally(() => {


        });
    }
  },
  mounted() {
    // this.$store.commit('setEnrollment', null)
    // alert(this.$store.getters.getEnrollment);
      if(!this.$route.query.application_id || this.$route.query.application_id=='undefined' || this.$route.query.application_id==null){
          if (localStorage.hasOwnProperty('tk')) {
              localStorage.removeItem('tk');
          }
          this.$router.push({name: 'pre-registration'});

      }
    this.getColors();
    this.getAllPreRegistrationLevels();
    this.isPreRegistrationActive();
    this.getEnrollmentByToken();
    this.getByOnlineAdmissionClosedContent();
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    applicationId() {
      return this.$route.query.application_id;
    },
    backgroundImage() {

      if (this.login_bg_path) {
        return this.login_bg_path;
      }
      return process.env.BASE_URL + "/";
    },
    bgColor() {

      if (this.login_bg_color) {
        return "background-color:" + this.login_bg_color;
      }
      return "background-color: #000000";
    },
    collegeColor() {
      if (this.college_color) {
        return "color:" + this.college_color;
      }
      return "color: #FFFFFF";
    },
    sloganColor() {
      if (this.slogan_color) {
        return "color:" + this.slogan_color;
      }
      return "color: #FFFFFF";
    }
  }
};
</script>


<style lang="scss" scope>
.login-aside {
  position: relative;
  z-index: 1;
  background-size: cover;
  flex: 1.5 !important;

  & > * {
    position: relative;
    z-index: 3;
  }

  .overlay-bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background-color: var(--login-bg-color);
  }

  /*&:after {*/
  /*    content: '';*/
  /*    position: absolute;*/
  /*    left: 0;*/
  /*    top: 0;*/
  /*    z-index: 2;*/
  /*    width: 100%;*/
  /*    height: 100%;*/
  /*    background-color: rgb(4, 69, 140, 0.7);*/
  /*}*/

  & + * {
    flex: 2 !important;
  }
}
</style>
